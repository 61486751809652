import axios from 'axios';
import { GetServerSideProps } from 'next';
import { getServerSideSitemap, ISitemapField } from 'next-sitemap';
import { apiConstants } from '../../constants/apiConstants';

export interface SlugsDataI {
  data: {
    data: {
      countryList: {
        countryName: string;
        roamingCountriesName: string;
        countryCode: string;
        region: string;
        type: string;
        lowestBundlePrice: number;
        networks: string;
        displaySlug: string;
      }[];
    };
  };
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const REGION = process.env.REGION ?? '';
  let fields: ISitemapField[] = [];
  const { data }: SlugsDataI = await axios.get(
    apiConstants.search + '/esim/country'
  );
  fields = data?.data?.countryList?.map((cur: any) => ({
    loc:
      process.env.NEXT_PUBLIC_SITE_URL ||
      'https://driffle.com' + `/esim/${cur.displaySlug}`,
    lastmod: new Date().toISOString(),
  }));
  return getServerSideSitemap(ctx, fields);
};

export default function Site() {}
